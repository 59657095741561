import React from 'react';
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp, FiCheck } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
// import { Accordion, Card } from 'react-bootstrap';
import StickyHeader from './ketamine/stickyheader.jsx';
// import faqs from "./ketamine/faqs.json"
import { Element } from 'react-scroll';
import Testimonial from './ketamine/testimonials';
import KetamineServices from './ketamine/services';
import FAQPage from './FaqPage/FaqPage'

function Ketamine(props) {

    return (
        <React.Fragment>
            <PageHelmet pageTitle='Provider Biographies' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--22" data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title color-1">Ketamine Therapy</h2>
                                <p>Discover relief and the transformative benefits of ketamine treatments at Redemption Psychiatry. Our treatments help alleviate depression, anxiety, OCD, PTSD, and chronic pain.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Sticky Header */}
            <StickyHeader />
            {/* End Sticky Header */}

            {/* Start Sections */}

            {/* Start What is Ketamine? Area  */}
            <div className="rn-finding-us-area rn-finding-us pt--10 bg_color--1" style={{ zIndex: "1" }}>
                <div className="inner">
                    <div className="content-wrapper">
                        <div className="content" style={{ opacity: "0.8" }}>
                            <h4>What is Ketamine?</h4>
                            <p>Ketamine treatment refers to the use of the medication ketamine, originally developed as an anesthetic, to treat various mental health conditions such as depression, anxiety, obsessive compulsive disorder (OCD), and post-traumatic stress disorder (PTSD). Given at lower doses, ketamine has shown promise in providing rapid relief from symptoms, especially when traditional therapies haven't been effective. It's administered under the care of a medical professional, typically through an IV, injections in the muscle, or a nasal spray.</p>
                            <a className="rn-btn btn-white" href="#benefits_and_efficacy">Learn more below!</a>
                        </div>
                    </div>
                    <div className="thumbnail">
                        <div className="image">
                            <img style={{ marginTop: '60px' }} src="/assets/images/bg/bg-image-5.jpg" alt="Finding Images" />
                        </div>
                    </div>
                </div>
            </div>
            {/* End What is Ketamine? Area  */}

            {/* Start Benefits and Efficacy Section */}
            <Element name="benefits_and_efficacy">
                <div style={{ paddingTop: "70px" }}></div>
                <button id="benefits_and_efficacy" style={{ display: 'block', position: "relative", top: "-70px", visibility: "hidden" }}></button>
                <div className="service-area ptb--20 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Benefits and Efficacy</h2>
                                    <p>Highlighting some of the benefits and efficacy of ketamine infusion therapy.</p>
                                </div>
                                <div className="content">
                                    <h3>Relief from Treatment-resistant Conditions</h3>
                                    <p>Ketamine therapy has shown promising results in providing relief for individuals with treatment-resistant depression, anxiety disorders, and chronic pain. Treatment resistance refers to patients who have not responded to traditional treatments, such as medications and therapy. Many of these patients have experienced significant improvements in their symptoms after undergoing ketamine therapy.</p>

                                    <h3>Rapid Onset of Action</h3>
                                    <p>One of the key advantages of ketamine therapy is its rapid onset of action. Unlike traditional antidepressant medications that can take weeks or even months to show noticeable effects, ketamine therapy often produces significant improvements within hours or days. This can provide much-needed relief for individuals who are suffering from severe depression or other debilitating conditions.</p>

                                    <h3>Long-lasting Effects</h3>
                                    <p>In addition to its rapid onset of action, ketamine therapy has also been found to have long-lasting effects for some patients. This means that even after completing the initial treatment course, individuals may continue to experience relief from their symptoms for an extended period. However, it's important to note that the duration of effects can vary from person to person, and additional maintenance treatments may be necessary.</p>

                                    <h3>Potential for Personal Growth</h3>
                                    <p>Beyond symptom relief, ketamine therapy has shown potential for promoting personal growth and improving overall well-being. Some individuals have reported enhanced self-awareness, improved mood, increased motivation, and a greater sense of clarity and purpose after undergoing ketamine therapy. These positive effects can contribute to a more fulfilling and meaningful life.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            {/* End Benefits and Efficacy Section */}

            {/* Start Services Section */}

            <KetamineServices />
            {/* End Services Section */}

            {/* Start Patient Testimonials Section */}
            <Testimonial />
            {/* End Patient Testimonials Section */}

            {/* Start Safety and Side Effects Section */}
            <Element name="safety_and_side_effects">
                <div style={{ paddingTop: "70px" }}></div>
                <button id="safety_and_side_effects" style={{ display: 'block', position: "relative", top: "-70px", visibility: "hidden" }}></button>
                <div className="service-area ptb--20 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Safety and Side Effects</h2>
                                    <p>Learn about the safety precautions and potential side effects of ketamine infusion therapy.</p>
                                </div>
                                <div className="content">
                                    <h3>Safety Precautions</h3>
                                    <p>Ketamine therapy is generally considered safe when administered by trained healthcare professionals in a controlled environment. At Redemption Psychiatry, we prioritize your safety throughout the treatment process. Here are some safety precautions we follow:</p>
                                    <ul>
                                        <li>Thorough evaluation: Before starting ketamine therapy, your psychiatric provider will conduct a comprehensive evaluation to ensure it is an appropriate treatment option for you.</li>
                                        <li>Qualified professionals: Our specialized ketamine nurses and medical staff are experienced in administering and monitoring ketamine treatments, ensuring your safety and well-being.</li>
                                        <li>Monitoring vital signs: During each treatment session, your vital signs, such as heart rate, blood pressure, and oxygen saturation, will be closely monitored to detect and manage any potential complications.</li>
                                        <li>Emergency preparedness: We have protocols in place and direct access to medical professionals who can promptly address any unforeseen emergencies or complications that may arise during the treatment.</li>
                                    </ul>

                                    <h3>Potential Side Effects</h3>
                                    <p>While ketamine therapy is generally well-tolerated, it's important to be aware of potential side effects. These side effects are typically temporary and subside shortly after the session ends. Some commonly reported side effects include:</p>
                                    <ul>
                                        <li>Mild dissociation: During the treatment, you may experience a sense of detachment or disconnection from your body or surroundings. This dissociation effect is usually temporary and resolves once the treatment is complete.</li>
                                        <li>Perceptual changes: Ketamine can cause perceptual disturbances, such as changes in vision, hearing, or sensations. These effects are usually transient and fade as the medication clears your system.</li>
                                        <li>Mood changes: Some individuals may experience fluctuations in mood during or after the treatment. This can include feelings of euphoria, tranquility, or temporary shifts in emotions. These effects are typically transient and should not be a cause for concern.</li>
                                        <li>Physical sensations: Ketamine may cause temporary physical sensations, such as tingling, numbness, or changes in limb size or weight. These sensations are generally harmless and should diminish as the medication wears off.</li>
                                    </ul>

                                    <p>It's important to note that the occurrence and intensity of side effects can vary from person to person. Your healthcare team will closely monitor your response during the treatment and make adjustments as necessary to ensure your safety and comfort.</p>

                                    <p>If you have any concerns about the safety or potential side effects of ketamine therapy, we encourage you to discuss them with your psychiatric provider. They will provide personalized guidance and address any questions or apprehensions you may have.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            {/* End Safety and Side Effects Section */}

            {/* Start Treatment Process Section */}
            <Element name="treatment_process">
                <div style={{ paddingTop: "70px" }}></div>
                <button id="treatment_process" style={{ display: 'block', position: "relative", top: "-70px", visibility: "hidden" }}></button>
                <div className="service-area ptb--20 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Treatment Process</h2>
                                    <p>Explore the step-by-step process of ketamine infusion therapy.</p>
                                </div>
                                <div className="content">
                                    <h3>Before starting treatment</h3>
                                    <p>Your psychiatric provider must determine that ketamine is an appropriate treatment for you. They will then order the ketamine nurse to administer the prescription. The first infusion appointment will take a little longer than subsequent treatments, but generally, you should plan for 90 min for the first treatment and 60 min for subsequent treatments. You will initially meet with the ketamine nurse and review the risks and benefits of the procedure to obtain your consent prior to starting the treatment. This gives you and your family the opportunity to ask any last-minute questions you may have before treatment begins.</p>

                                    <h3>Preparing for the infusion</h3>
                                    <p>You should wear comfortable clothing that allows easy access to your forearms for IV placement. Unless otherwise instructed, you should take your medications as you normally would but avoid taking any new medications or as needed medications for 8 hours prior to the procedure.</p>

                                    <h3>The procedure setting</h3>
                                    <p>You will be in a quiet, relaxing, private or semi-private space with a comfortable recliner and soft lighting. There may be space in the room for a friend or family member to stay with you for support. You can talk normally, but you shouldn't expect to have much meaningful conversation since you will find that you'll want a calm and quiet environment. We provide some optional items to help you stay relaxed and comfortable such as a soft blanket, fluffy pillows, water to sip, and noise canceling headphones to block ambient sound and listen to music during the treatment.</p>

                                    <h3>Starting the IV (for IV ketamine only)</h3>
                                    <p>Our specialized ketamine nurses are very experienced with IV placement and will place the IV catheter using a small-gauge needle as painlessly as possible to use for the infusion. Prior to starting the Ketamine drip, a medication such as atropine (or glycopyrrolate) is administered to safely and effectively prevent some of the possible side effects of Ketamine including excessive salivation and nausea.</p>

                                    <h3>During the treatment</h3>
                                    <p>A few minutes after the treatment begins, you will likely start to experience a peaceful and relaxing state that feels disconnected from your body and the world around you. This is a state of dissociation that may include perceptual disturbances, which most people have a difficult time describing. Some patients describe difficulty perceiving the passage of time, some see colors, some describe interesting feelings in their bodies like tingling, numbness, or increased size of limbs.</p>

                                    <h3>Getting the most out of treatment</h3>
                                    <p>The level of dissociation is not always the same from one treatment to another and the benefit of the treatment is not dependent on having an intense experience. Many factors play a role in the patient's subjective experience during the treatment, which depending on the patient, may include feeling anxious, being sleep deprived, being in pain, and the level of comfort with the procedure. We strongly advise patients to approach Ketamine treatments with the outcome in mind, especially if the temporary feeling of dissociation may make you feel anxious or uncomfortable.</p>

                                    <h3>Assuring safety</h3>
                                    <p>The nurse will monitor your vital signs during the treatment including heart rate, blood pressure, and oxygen saturation, and following the doctor's orders for your treatment, the nurse may adjust the Ketamine drip rate (for IV treatments) or administer comfort medications to optimize your response and prevent side effects. The doctor can be reached at a moment's notice if needed.</p>

                                    <h3>Dosing the treatment</h3>
                                    <p>We will start with a low-dose , typically at 0.5 mg/kg, consistent with accepted protocols that have been developed and researched since the early 2000’s. In order to find the most effective dose, we recommend always starting with IV treatments so we have the ability to speed up or slow down the infusion rate and/or raise or lower the dose. These adjustments can be made during an infusion (infusion rate) or adjusted for subsequent treatments (infusion rate and dose). You may experience different feelings and sensations from one treatment to the next regardless of whether any changes were made, and this is all very normal. There is no typical sensation a patient should experience in order to have a good effect. Although we may increase a patient's dose, unlike most medication treatments, a higher dose of Ketamine is not necessarily more effective, which is why the benefits of Ketamine for mood were not apparent over decades of using high doses for anesthesia.</p>

                                    <h3>Following treatment</h3>
                                    <p>The nurse will remove the IV catheter after the treatment and allow you to fully recover before you leave. You will need someone to drive you home because although you may feel fine, your reflexes will be slowed and equilibrium may be slightly off, so you will not be safe to drive, care for children, or make important decisions until you've had a full night's sleep to reset. We also have patients who choose to take a cab or an Uber; although this is not preferred, since we feel it would be best to travel with someone you know and trust. The medication will rapidly leave your system as soon as the treatment is completed, so you will quickly feel the effects of ketamine dissipate. However, lingering side effects such as slowed reflexes and poor equilibrium may persist until you have had a night of sleep, which is why you need a ride home.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            {/* End Treatment Process Section */}

            {/* Start Pricing Section */}
            <Element name="pricing">
                {/* Start Pricing Tbale Area  */}
                <div style={{ paddingTop: "70px" }}></div>
                <button id="pricing" style={{ display: 'block', position: "relative", top: "-70px", visibility: "hidden" }}></button>
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="section-title text-center mb--30">
                                <h2>Pricing Options</h2>
                                <p>A typical course of treatment is usually approximately $2000 out of pocket, which includes the first probono and 5 follow up treatments. The treatment generally involves an initial round of six infusions and continued maintenance infusions as needed every one to three months until long-term remission is sustained. For this reason, we offer a steep one-time discount for those who know they want to complete a full series.</p>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header" style={{padding: '0'}}>
                                            <h4 className="title">First Treatment</h4>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Price of consult varies based on patient insurance (covered by most private insurances)</li>
                                                <li><FiCheck /> Meet with our expert physicians</li>
                                                <li><FiCheck /> Determine if treatment is right for you</li>
                                                <li><FiCheck /> Pay for only the treatments you need!</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Follow-up Treatment</h4>
                                            <div className="pricing">
                                                <span className="price">$500</span>
                                                <span className="subtitle">(OR LESS!)</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 40-60 minute low-dose infusion</li>
                                                <li><FiCheck /> Patients typically spend 1.5 hours in the office</li>
                                                <li><FiCheck /> Spa-like environment</li>
                                                <li><FiCheck /> The most appropriate course of treatments will be different for each patient</li>
                                                <li><FiCheck /> Healthcare Savings Accounts (HSA) can be used</li>
                                                <li><FiCheck /> Payment plans are available</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header" style={{padding: '0'}}>
                                            <h4 className="title">Community Heroes</h4>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> We show our appreciation to our community heros (first responder, medical professional, military, and others)</li>
                                                <li><FiCheck /> Call us for details regarding our discount program</li>
                                                <li><FiCheck /> **Tricare members: we offer 6 ketamine infusions for free with an approved course of TMS treatment as part of our commitment to the military</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}
            </Element>
            {/* End Pricing Section */}

            {/* Start FAQs Section */}
            <Element name="faqs">
                <button id="faqs" style={{ display: 'block', position: "relative", top: "-70px", visibility: "hidden" }}></button>
                <FAQPage />
            </Element>
            <br /><br /><br />
            {/* End FAQs Section */}
            {/* End Sections */}

            <Footer />
            {/* Start Back To Top */}
            <div className="backto-top-wrap">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </React.Fragment>
    )
}

export default Ketamine;
