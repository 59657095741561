import React from "react";
import { useForm } from 'react-hook-form';
import Axios from "axios";
import NumberFormat from 'react-number-format';
import { Modal } from 'react-bootstrap'


export default function ContactTwo() {
    const { register, handleSubmit, errors } = useForm();
    const [tel, setTel] = React.useState("");
    const [open, setOpen] = React.useState(false);
    // const [charsLeft, setCharsLeft] = React.useState("255")
    const [charsUsed, setCharsUsed] = React.useState("0")
    const onSubmit = (data, e) => {
        Axios.post('/api/contactforms/create-contact-email', data)
        e.target.reset(); // reset after form submit
        setOpen(true);
        setTel("")
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleWordCount = event => {
        const charCount = event.target.value.length;
        // const charLeft = 255 - charCount;
        setCharsUsed(charCount);
        // setCharsLeft(charLeft);
    }
    return (
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
                            <h2 className="title">Contact Us.</h2>
                            <p className="description">Please leave us a brief message and one of our experienced team members will contact you within 48 hours, thank you!</p>
                        </div>
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="item00"> Select a Topic:
                                    <select
                                        name="department"
                                        id="item00"
                                        ref={register()}>
                                        <option value="admin@rpsych.com">Employment</option>
                                        <option value="tms@rpsych.com">TMS</option>
                                        <option value="ketamine@rpsych.com">Ketamine</option>
                                        <option value="billing@rpsych.com">Billing</option>
                                        <option value="scheduling@rpsych.com">Referral</option>
                                        <option value="nurse@rpsych.com">Medication</option>
                                        {/* <option value="scheduling@rpsych.com">New Patient</option> */}
                                        <option value="admin@rpsych.com">Other</option>
                                    </select>
                                    <div style={{ color: "red" }}>{errors.name && errors.name.message}</div>
                                </label>
                                <br />
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        placeholder="Your Name *"
                                        ref={register({
                                            required: 'Please enter your name',
                                            maxLength: {
                                                value: 30,
                                                message: 'Max length is 30',
                                            },
                                        })}
                                    />
                                    <div style={{ color: "red" }}>{errors.name && errors.name.message}</div>
                                </label>

                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        placeholder="Your email *"
                                        ref={register({
                                            required: 'Please enter your email',
                                            pattern: {
                                                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                message: 'Invalid email address',
                                            },
                                        })}
                                    />
                                    <div style={{ color: "red" }}>{errors.email && errors.email.message}</div>
                                </label>

                                <label htmlFor="item03">
                                    <NumberFormat
                                        name="tel"
                                        id="item03"
                                        value={tel}
                                        onChange={e => setTel(e.target.value)}
                                        placeholder="(555) 555-5555"
                                        format="(###) ###-####"
                                        mask="_"
                                        getInputRef={register({
                                            pattern: {
                                                value: /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/,
                                                message: 'Invalid phone number',
                                            },
                                        })}
                                    />
                                    <div style={{ color: "red" }}>{errors.tel && errors.tel.message}</div>
                                </label>
                                <label htmlFor="item04">
                                    <textarea
                                        type="text"
                                        id="item04"
                                        name="message"
                                        onChange={handleWordCount}
                                        placeholder="Your Message"
                                        maxLength={255}
                                        ref={register({
                                            required: 'Please write a message you would like to send us.',
                                            maxLength: {
                                                value: 255,
                                                message: 'Max length is 255 characters',
                                            }
                                        })}
                                    />
                                    <p style={{ textAlign: "right", fontSize: "0.9rem", marginTop: "-20px" }}>{charsUsed}/255</p>
                                    <div>{errors.message && errors.message.message}</div>
                                </label>
                                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="mb_md--30 mb_sm--30">
                            <img src="/assets/images/about/about-7.png" alt="Contact Us" />
                        </div>
                    </div>
                    <Modal show={open} onClose={handleClose}>
                        <Modal.Header closeButton onClick={handleClose}>
                        </Modal.Header>
                        <Modal.Body>
                            <h3 style={{ textAlign: "center", margin: "auto" }}>Thank You!</h3>
                            <div style={{ textAlign: "center" }}>
                                A member of our staff will return your message as soon as possible. <br />
                                Have a nice day!
                                <hr />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="rn-button-style--2 btn-solid" onClick={handleClose}>
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    );
}




// class extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             rnName: '',
//             rnEmail: '',
//             rnSubject: '',
//             rnMessage: '',
//         };
//     }
//     render() {
//         return (
//             <div className="contact-form--1">
//                 <div className="container">
//                     <div className="row row--35 align-items-start">
//                         <div className="col-lg-6 order-2 order-lg-1">
//                             <div className="section-title text-left mb--50">
//                                 <h2 className="title">Contact Us.</h2>
//                                 <p className="description">Please leave us a brief message and one of our experienced TMS Coordinators will contact you within 48 hours, thank you!</p>
//                             </div>
//                             <div className="form-wrapper">
//                                 <form>
//                                     <label htmlFor="item01">
//                                         <input
//                                             type="text"
//                                             name="name"
//                                             id="item01"
//                                             value={this.state.rnName}
//                                             onChange={(e) => { this.setState({ rnName: e.target.value }); }}
//                                             placeholder="Your Name *"
//                                         />
//                                     </label>

//                                     <label htmlFor="item02">
//                                         <input
//                                             type="text"
//                                             name="email"
//                                             id="item02"
//                                             value={this.state.rnEmail}
//                                             onChange={(e) => { this.setState({ rnEmail: e.target.value }); }}
//                                             placeholder="Your email *"
//                                         />
//                                     </label>

//                                     <label htmlFor="item03">
//                                         <input
//                                             type="text"
//                                             name="subject"
//                                             id="item03"
//                                             value={this.state.rnSubject}
//                                             onChange={(e) => { this.setState({ rnSubject: e.target.value }); }}
//                                             placeholder="Write a Subject"
//                                         />
//                                     </label>
//                                     <label htmlFor="item04">
//                                         <textarea
//                                             type="text"
//                                             id="item04"
//                                             name="message"
//                                             value={this.state.rnMessage}
//                                             onChange={(e) => { this.setState({ rnMessage: e.target.value }); }}
//                                             placeholder="Your Message"
//                                         />
//                                     </label>
//                                     <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
//                                 </form>
//                             </div>
//                         </div>
//                         <div className="col-lg-6 order-1 order-lg-2">
//                             <div className="mb_md--30 mb_sm--30">
//                                 <img src="/assets/images/about/about-7.png" alt="trydo" />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }
// export default ContactTwo;