import React, { Component, Fragment } from "react";
import ReactTextCollapse from "react-text-collapse";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick, slickDot } from "../../scripts/script";
// import BlogContent from "../elements/blog/BlogContent";
import PodcastContent from "../elements/blog/PodcastContent";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import { FiChevronUp, FiChevronDown, FiCheck } from "react-icons/fi";
// import CounterOne from "../elements/counters/CounterOne";
import BrandTwo from "../elements/BrandTwo";
import CounterOne from "../elements/counters/CounterOne";
import TellUsWhatYouThink from "../elements/counters/TellUsWhatYouThink";
import BrandReviews from "../elements/BrandReviews";

// custom icons
import { ReactComponent as Brain } from "../../public/assets/images/icons/Picture1.svg"
import { ReactComponent as Building } from "../../public/assets/images/icons/Picture2.svg"
import { ReactComponent as Star } from "../../public/assets/images/icons/Picture3.svg"
import { ReactComponent as Cross } from "../../public/assets/images/icons/Picture4.svg"
import { ReactComponent as Brain2 } from "../../public/assets/images/icons/Picture5.svg"
import { ReactComponent as Shield } from "../../public/assets/images/icons/Picture6.svg"
import { ReactComponent as Triangle } from "../../public/assets/images/icons/Picture7.svg"
import { ReactComponent as Cloud } from "../../public/assets/images/icons/Picture8.svg"
import { ReactComponent as Hands } from "../../public/assets/images/icons/Picture9.svg"


const SlideList1 = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--32',
        title: 'call us now (480) 471-8560',
        category: '',
        buttonText: 'Learn more now!',
        buttonLink: '/about'
    },
]
const SlideList2 = [
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--14',
        category: '',
        title: 'We accept most commercial insurances',
        description: "(but we don't accept medicare/medicaid or AHCCCS)",
        buttonText: 'View Locations/Contact Info',
        buttonLink: '/contact'
    },
]
const SlideList3 = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--16',
        category: '',
        title: 'Schedule An Appointment',
        description: 'Plase give us a call, send us an email, or fill out the form on our contact page. We would love to get in touch with you as soon as possible, however is most convenient for you!',
        buttonText: 'Contact Us',
        buttonText2: 'New Patients',
        buttonLink: '/contact',
        buttonLink2: '/newpatients'
    },
]

const TEXT_COLLAPSE_OPTIONS = {
    collapse: false, // default state when component rendered
    collapseText: <div className="iconHover" style={{}}><h6 style={{ display: "inline", color: "#4472C4" }}> Show More </h6><FiChevronDown /></div>, // text to show when collapsed
    expandText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show Less </h6><FiChevronUp /></div>, // text to show when expanded
    minHeight: 0, // component height when closed
    maxHeight: 600, // expanded to
    color: "black",
    textStyle: { // pass the css for the collapseText and expandText here
        color: "#003C8F",
        fontSize: "15px",
        marginLeft: "35%",
    }
}

const WhyUSList = [
    {
        icon: <Brain height="84px" viewBox="0 0 104 104" />,
        title: 'We specialize in psychiatry and treatment-resistant disorders',
        description: ['Redemption Psychiatry TMS providers have performed over 60,000 TMS procedures and are highly specialized in TMS techniques that are customized to each patient including adding extended treatments and off-label protocols at no extra charge. Redemption Psychiatry TMS doctors are board-certified by the American Board of Psychiatry and Neurology.'],
    },
    {
        icon: <Building height="84px" viewBox="0 0 104 104" />,
        title: 'We are one of the largest private psychiatric practices in Arizona.',
        description: ['Redemption Psychiatry offers TMS at four locations including Phoenix, Chandler, Mesa and Gilbert for your convenience. That doesn’t happen overnight and comes from having many happy clients, valued relationships with referral sources, and excellent outcomes. We maintain a high quality of care and attract more and more exceptional mental health providers. This has enabled us to have experts in many areas of psychiatric practice at several locations with some of the most advanced treatments available. No single doctor can be the right person to help all patients, so having several psychiatrists allows us the greatest potential to help.'],
    },
    {
        icon: <Star height="84px" viewBox="0 0 104 104" />,
        title: 'Our expertise in the use of advanced psychiatric treatments.',
        description: ['Advanced psychiatric treatments and interventions encompass a relatively new area of practice that does not have a specialty board. This means that in order to become experts in this area, instead of being able to graduate a specialty fellowship program and take a board exam, doctors must seek out training on their own, attend national conferences, and keep up to date with the research. More importantly, to become an expert in any area of medicine, doctors must use their skills regularly and handle all kinds of unusual situations. To accomplish this, they must have experience in dealing with a wide variety of patients from all demographics with different combinations of medications and medical disorders. At Redemption Psychiatry, we check all these boxes because we have a very large volume practice including one of the largest volume advanced psychiatric treatment departments in Arizona. You can rest assured that you are working with experts.'],
    },
    {
        icon: <Cross height="84px" viewBox="0 0 104 104" />,
        title: 'We have many more treatment options in addition to TMS.',
        description: ['Beware of clinics and psychiatrists who only provide TMS treatment and do not provide the most basic psychiatric treatments such as diagnostic evaluations and medication management. Although these clinics may claim their focus on TMS makes them the best qualified to perform the treatment, that is not the case. The fact is, any clinic that only performs costly procedures is profit-driven and will be motivated to get as many patients to undergo TMS treatment as possible. Every patient is complex and needs a TMS expert who takes a patient-centered approach, which means they must maintain their essential psychiatric skills and should develop a myriad of additional skills to care for treatment-resistant conditions such as TBS and Ketamine infusion therapy.'],
    },
    {
        icon: <Brain2 height="84px" viewBox="0 0 104 104" />,
        title: 'Our clinic’s TMS doctors have received local and national recognition in the media',
        description: [
            ["Redemption Psychiatry TMS doctors have had many television appearances including: "],
            [<a style={{ color: '#294D8F', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=nQq3VIBQg7s">Sonoran Living</a>],
            [" "],
            [<a style={{ color: '#294D8F', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=fRShMOV0EQY">Fox 10 News Phoenix</a>],
            [" "],
            [<a style={{ color: '#294D8F', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=cK4BnRw1EYM">American Health Front on NBC</a>],
            [" "],
            [<a style={{ color: '#294D8F', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=JO-CzTxx0hQ">Success Files with Rob Lowe</a>],
            ["Click any of the links above to watch the video!"]
        ],
    },
    {
        icon: <Shield height="84px" viewBox="0 0 104 104" />,
        title: 'Highly-trained, skilled staff',
        description: ['There is currently no licensure for TMS technicians and treatment centers are responsible for maintaining adequate training and quality standards. Our staff are highly trained and professional with constant support and the most up-to-date, proven, research-based techniques and equipment.'],
    },
    {
        icon: <Triangle height="84px" viewBox="0 0 104 104" />,
        title: 'We accept referrals from other clinics/providers.',
        description: ['Although we are happy to be your primary pyschiatric health care provider, we understand that finding a pyschiatrist who is the right fit for you can be challenging. While it is ideal to receive medication management at the same clinic where you receive TMS, this is not always possible, which is why we will coordinate care with your psychiatrist during your treatment for the best possible outcome. Redemption Psychiatry is one of the largest and most well-known private psychiatric practices in Arizona. We work hard to maintain good relationships with all our colleagues to enhance communication to promote improved outcomes.'],
    },
    {
        icon: <Cloud height="84px" viewBox="0 0 104 104" />,
        title: 'We have the most advanced TMS equipment capable of the latest treatment modalities including Theta Burst Stimulation (TBS)',
        description: ['Redemption psychiatry was one of the first clinic to provide equipment capable of not only the traditional high-frequency TMS for exciting neural pathways and low-frequency TMS for inhibiting neural pathways, but also capable of Theta Burst Stimulation. Intermittent TBS provides the same excitation of the brain in less than half the time and continuous TBS provides the same inhibition in only 40 seconds. Beware of clinics that boast the most expensive, “industry leading” device or a “deep” TMS device claiming that these are superior devices even though they have not been proven to have better outcomes and they are not capable of TBS treatments nor can they be positioned to stimulate all possible cortical treatment targets.'],
    },
    {
        icon: <Hands height="84px" viewBox="0 0 104 104" />,
        title: 'We offer other advanced psychiatric treatments',
        description: ['Redemption Psychiatry offers the widest array of advanced treatments including Ketamine infusion therapy (often in combination with TMS), Theta Burst Stimulation, and Deep Brain Stimulation (coming soon). We refer clients to our network of partners providing intensive outpatient treatment, partial hospitalization programs, and electroconvulsive therapy. We are also able to improve patient outcomes following TMS treatment by providing medication management that will take advantage of the increased activity in the prefrontal cortex achieved with TMS treatment.'],
    },
]
const starndardService = [
    {
        image: '01',
        title: 'New Patient Consultation',
        description: 'If you have not had any treatment before, you will need to meet with one of our doctors first.',
        href: '/services'
    },
    {
        image: '02',
        title: 'Ketamine Infusions',
        description: 'Click "Read More" to visit our infusions website to learn how this advanced treatment option may be right for you.',
        href: 'https://rpsych.com/ketamine'
    },
    {
        image: '03',
        title: 'Transcranial Magnetic Stimulation (TMS)',
        description: 'Click "Read More" to visit our TMS website to learn how this advanced treatment option may be right for you.',
        href: 'https://rpsychtms.com/'
    },

]

class CorporateBusiness extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isOpen2: false
        }
        this.openModal = this.openModal.bind(this)
        this.openModaltwo = this.openModaltwo.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    openModaltwo() {
        this.setState({ isOpen2: true })
    }
    render() {

        var namesItemOne = [
            'Patient Focused – Providing quality patient care is our primary function, the patient is the center of our practice, all decisions that impact our patients shall always be towards the greatest good.',
            'Economical – We must be frugal and provide the best value to our patients and hold ourselves to the highest standards.',
            'Innovative – Staying at the forefront of the mental healthcare community means always finding new ways and leveraging technology to accomplish tasks.',
            'Accountable - We must hold ourselves responsible for our own actions. While maintaining compassion for extenuating circumstances, we also expect patients to be accountable.',
        ];
        var namesItemTwo = [
            'Integrity – Always being honest in all we do, giving the most accurate information and not being afraid to tell the truth. Having the utmost moral integrity, especially in matters regarding patient confidentiality or financial matters.',
            'Compassion – Being understanding of persons in our care and conducting ourselves in an empathetic manner in all we do.',
        ];

        // const PostList = BlogContent.slice(0, 4);
        const PostList2 = PodcastContent.slice(0, 4);

        return (
            <Fragment>
                <Helmet pageTitle="Homepage" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation pt--130">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList1.map((value, index) => (
                                <div className={`slide slide-style-3 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title" style={{ color: 'white', textShadow: '2px 2px 2px rgba(0,0,0,0.6)' }}>{value.title}</h1> : ''}
                                                    <p style={{ color: 'white', textShadow: '2px 2px 2px rgba(0,0,0,0.6)' }}>Our Vision: To redeem the practice of Psychiatry for providers and patients, remove any stigma towards mental illness, and stay at the forefront of advancements in Psychiatric treatment.</p>
                                                    {value.buttonText ? <div className="slide-btn" ><a className="rn-button-style--2 btn-solid" style={{ boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.75)', textShadow: '2px 2px 2px rgba(0,0,0,0.6)' }} href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {SlideList2.map((value, index) => (
                                <div className={`slide slide-style-4 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`} style={{ marginBottom: "220px" }}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h2 className="title" style={{ color: 'white', textShadow: '2px 2px 2px rgba(0,0,0,0.6)' }}>{value.title}</h2> : ''}
                                                    {value.description ? <p className="description" style={{ textShadow: '2px 2px 2px rgba(0,0,0,0.6)' }}>{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" style={{ boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.75)', textShadow: '2px 2px 2px rgba(0,0,0,0.6)' }} href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {SlideList3.map((value, index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title" style={{}}>{value.title}</h1> : ''}
                                                    {value.description ? <p className="description" style={{ fontWeight: '900', backdropFilter: 'blur(15px)', opacity: '1' }}>{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a style={{ marginRight: '20px', boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.75)', textShadow: '2px 2px 2px rgba(0,0,0,0.6)' }} className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a><a className="rn-button-style--2 btn-solid" style={{ boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.75)', textShadow: '2px 2px 2px rgba(0,0,0,0.6)' }} href={`${value.buttonLink2}`}>{value.buttonText2}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--50 bg_color--5">
                    <div className="container">
                        <div className="section-title">
                            <h2 className="title" style={{ textAlign: "center" }}>Why Choose Redemption?</h2>
                        </div>
                        <div className="row row--35 align-items-center">
                            {WhyUSList.map((value, index) => (
                                <div className="col-lg-4" style={{ textAlign: "center" }} key={index}>
                                    <div style={{ margin: "auto", textAlign: "center", marginTop: "50px" }}>
                                        {value.icon}
                                    </div>
                                    <p style={{ marginTop: "0px", fontWeight: "bold", textAlign: "center" }}>{value.title}</p>
                                    <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                                        {value.description.flat().map((item, idx) => (
                                            <React.Fragment key={`${index}-${idx}`}>
                                                {typeof item === 'string' ? <p>{item}</p> : item}
                                            </React.Fragment>
                                        ))}
                                    </ReactTextCollapse>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="mt--30">
                                        <h4>We are…</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={index}><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                    <div className="mt--30">
                                        <h4>We have…</h4>
                                        <ul className="list-style--1">
                                            {namesItemTwo.map((name, index) => {
                                                return <li key={index}><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                    <div className="mt--30">
                                        <h4>We deliver…</h4>
                                        <ul className="list-style--1">
                                            <li key="quality"><FiCheck />Quality – We will rigorously perform our duties to the best of our ability and consistently deliver quality care to our patients.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/Dr-Friday-Video.png" alt="About Images" />
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='cK4BnRw1EYM' onClose={() => this.setState({ isOpen: false })} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pb--80 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">Services</h2>
                                    <p>We offer multiple different Advanced Treatments for our clients in addition to therapy and medication management.</p>
                                </div>
                            </div>
                            {/* End Single Service  */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {starndardService.map((value, index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <img src={`/assets/images/featured/corporate-${value.image}.jpg`} alt="Corporate Images" />
                                                </div>
                                                <div className="content">
                                                    <h3><a href={value.href}>{value.title}</a></h3>
                                                    <p>{value.description}</p>
                                                    <a className="btn-transparent rn-btn-dark" href={value.href}><span className="text">Read More</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>
                </div>
                {/* End Featured Service Area  */}

                {/* Start Counterup Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div>
                {/* End Counterup Area */}

                {/* Start Discharge Planners Area */}
                <div className="rn-about-area pt--120 bg_color--1">
                    <div className="container">
                        <div className="section-title" style={{ textAlign: "center" }}>
                            <h2 className="title">For Discharge Planners</h2>
                            <p>We understand the importance of coordinated care during the discharge process. Our team is committed to working with discharge planners to ensure that patients transitioning from inpatient to outpatient care receive the support they need. Please contact our Discharge Coordinator to discuss how we can assist with your patient's discharge plan: </p>
                            <p>referrals@rpsych.com</p>
                            <p>(480) 870-4181</p>
                        </div>
                    </div>
                </div>
                {/* End Discharge Planners Area */}


                {/* Start Reviews Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--1">
                    <BrandReviews />
                </div>
                {/* End Reviews Top Area  */}


                {/* Start TellUsWhatYouThink Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--16 theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <TellUsWhatYouThink />
                    </div>
                </div>
                {/* End TellUsWhatYouThink Area */}


                {/* Start Counterup Area */}
                {/* <div className="counterup-area pb--80 pt--40 bg_image bg_image--3 theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div> */}
                {/* End Counterup Area */}

                {/* Start Finding Us Area  */}
                {/* <div className="rn-finding-us-area rn-finding-us pt--40 bg_color--1" style={{ zIndex: "1" }}>
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content" style={{ opacity: "0.8" }}>
                                <h4>What is TMS?</h4>
                                <p>TMS stands for Transcranial Magnetic Stimulation. It is a noninvasive, well-tolerated procedure that uses pulsed magnetic fields to stimulate nerve cells in the brain to condition long-lasting changes in dysfunctional neural pathways without the systemic side effects that often occur with medications. </p>
                                <a className="rn-btn btn-white" href="/tmsinfo">Learn more!</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/finding-us-01.png" alt="Finding Images" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Finding Us Area  */}

                {/* Start Team Area  */}
                {/* <div className="rn-team-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Managing Team</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" teamStyle="" item="4" />
                        </div>
                    </div>
                </div> */}
                {/* End Team Area  */}
                {/* Start Portfolio Area */}
                <div className="portfolio-area pt--120 pb--80 bg_color--5">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--15">
                                        <h2 className="title">Redemption Psychiaty Podcasts</h2>
                                        <p>Check out some of our company's podcasts!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <img className="col-lg-6" src="/assets/images/podcast/trailer.png" alt="About Images" />
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='Y2thkVH5ALQ' onClose={() => this.setState({ isOpen2: false })} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModaltwo}><span className="play-icon"></span></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {PostList2.map((value, index) => (
                                                <div className="portfolio" key={index}>

                                                    <div className="thumbnail-inner">
                                                        <a href={value.link}>
                                                            <img className="w-100" src={`/assets/images/podcast/podcast-${value.images}.jpg`} alt="Podcast Images" />
                                                        </a>
                                                    </div>
                                                    <br />
                                                    <div className="content">
                                                        <div style={{ alignContent: 'center', margin: '-20px', marginLeft: '1px' }} className="inner">
                                                            <h4 style={{ marginBottom: '-25px' }}><a href={value.link}>{value.title}</a></h4>
                                                            <div className="portfolio-button">
                                                                <a className="rn-btn" href={value.link}>Watch Podcast</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Brand Area  */}
                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;